import { defineComponent, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    props: {
        onRevert: {
            type: Function,
            default: () => { }
        },
        onSave: {
            type: Function,
            default: () => { }
        },
        onClose: {
            type: Function,
            default: () => { }
        },
        isChanged: {
            type: Function,
            default: () => { }
        },
        isSaved: {
            type: Function,
            default: () => { }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        page: {
            type: String,
            default: 'profile'
        }
    },
    setup(props, context) {
        const { root } = context;
        const toast = useToast();
        watch(() => props.isSaved(), val => {
            if (val) {
                toast.success(root.$i18n.t(`${props.page}.footer.close_label`));
                props.onClose();
            }
        });
    }
});
